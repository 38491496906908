<template>
  <div>
    <b-row>
      <b-col cols="12">
        <b-card no-body>
          <b-card-header class="align-items-baseline">
            <div>
              <b-card-title class="mb-25">
                Total Transaksi
              </b-card-title>
              <b-card-text class="mb-0">
                <!-- Total Omzet: {{ data.omzet }} -->
              </b-card-text>
            </div>
            <div>
              <b-form-select
                v-model="data.activeYear"
                :options="yearOption"
                @change="changeYear"
              />
              <!-- <b-dropdown
                text="2020"
                size="sm"
                class="budget-dropdown"
                variant="outline-primary"
              >
                <b-dropdown-item v-for="(item, i) in years" :key="i">
                  <span @click="changeYear">{{ item.year }}</span>
                </b-dropdown-item>
              </b-dropdown> -->
            </div>
          </b-card-header>

          <b-card-body class="pb-0">
            <!-- apex chart -->
            <vue-apex-charts
              ref="chartTransaction"
              type="line"
              height="240"
              :options="salesLine.chartOptions"
              :series="salesLine.series"
            />
          </b-card-body>
        </b-card>
      </b-col>
      <b-col cols="12">
        <b-card no-body>
          <b-card-header class="align-items-baseline">
            <div>
              <b-card-title class="mb-25">
                Omzet
              </b-card-title>
              <b-card-text class="mb-0">
                <!-- Total Omzet: {{ data.omzet }} -->
              </b-card-text>
            </div>
            <div>
              <!-- <b-dropdown
                text="2020"
                size="sm"
                class="budget-dropdown"
                variant="outline-primary"
              >
                <b-dropdown-item v-for="(item, i) in years" :key="i">
                  <span @click="changeYear">{{ item.year }}</span>
                </b-dropdown-item>
              </b-dropdown> -->
            </div>
          </b-card-header>

          <b-card-body class="pb-0">
            <!-- apex chart -->
            <vue-apex-charts
              ref="chartOmzet"
              type="line"
              height="240"
              :options="omzetLine.chartOptions"
              :series="omzetLine.series"
            />
          </b-card-body>
        </b-card>
      </b-col>
      <b-col cols="12">
        <b-card-code
          title="Top Products"
          no-body
        >
          <b-card-body>
            <div class="d-flex justify-content-between  flex-wrap">
              <!-- sorting  -->
              <b-form-group
                label="Sort"
                label-size="sm"
                label-align-sm="left"
                label-cols-sm="3"
                label-for="sortBySelect"
                class="mr-1 mb-md-0"
              >
                <b-input-group
                  size="sm"
                >
                  <b-form-select
                    id="sortBySelect"
                    v-model="product.sortBy"
                    :options="sortOptions"
                  >
                    <template #first>
                      <option value="">
                        none
                      </option>
                    </template>
                  </b-form-select>
                  <b-form-select
                    v-model="product.sortDesc"
                    size="sm"
                    :disabled="!product.sortBy"
                  >
                    <option :value="false">
                      Asc
                    </option>
                    <option :value="true">
                      Desc
                    </option>
                  </b-form-select>
                </b-input-group>
              </b-form-group>

              <!-- filter -->
              <b-form-group
                label="Filter"
                label-cols-sm="2"
                label-align-sm="left"
                label-size="sm"
                label-for="filterInput"
                class="mb-0"
              >
                <b-input-group size="sm">
                  <b-form-input
                    id="filterInput"
                    v-model="product.filter"
                    type="search"
                    placeholder="Type to Search"
                  />
                  <b-input-group-append>
                    <b-button
                      :disabled="!product.filter"
                      @click="product.filter = ''"
                    >
                      Clear
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </div>
          </b-card-body>

          <b-table
            striped
            hover
            responsive
            class="position-relative"
            :per-page="product.perPage"
            :current-page="product.currentPage"
            :items="product.items"
            :fields="product.fields"
            :sort-by.sync="product.sortBy"
            :sort-desc.sync="product.sortDesc"
            :sort-direction="product.sortDirection"
            :filter="product.filter"
            :filter-included-fields="product.filterOn"
          >
            <template #cell(omzet)="dataOmzet">
              {{ dataOmzet.item.omzet | currencyIndo }}
            </template>
            <template #cell(price)="dataPrice">
              {{ dataPrice.item.price | currencyIndo }}
            </template>
          </b-table>

          <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
            <!-- page length -->
            <b-form-group
              label="Per Page"
              label-cols="6"
              label-align="left"
              label-size="sm"
              label-for="sortBySelect"
              class="text-nowrap mb-md-0 mr-1"
            >
              <b-form-select
                id="perPageSelect"
                v-model="product.perPage"
                size="sm"
                inline
                :options="product.pageOptions"
              />
            </b-form-group>

            <!-- pagination -->
            <div>
              <b-pagination
                v-model="product.currentPage"
                :total-rows="product.totalRows"
                :per-page="product.perPage"
                first-number
                last-number
                prev-class="prev-item"
                next-class="next-item"
                class="mb-0"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </b-card-body>
        </b-card-code>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol, BButton,
  BCard, BCardHeader, BCardTitle, BCardBody, BCardText,
  BTable, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BInputGroupAppend,
} from 'bootstrap-vue'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import VueApexCharts from 'vue-apexcharts'
import Ripple from 'vue-ripple-directive'
import { $themeColors } from '@themeConfig'

export default {
  components: {
    VueApexCharts,
    BCardCode,
    BRow,
    BCol,
    BButton,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BCardText,
    BTable,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
  },
  directives: {
    Ripple,
  },
  filters: {
    currencyIndo(val) {
      return `Rp. ${new Intl.NumberFormat('de-DE', { currency: 'EUR' }).format(val)}`
    },
  },
  data() {
    return {
      trxData: [
        {
          year: 2019,
          omzet: '2.8K',
          seriesData: [5, 10, 10, 17, 13, 15],
          labelData: ['Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        },
        {
          year: 2020,
          omzet: '10.8K',
          seriesData: [18, 30, 20, 30, 20, 13, 9, 12, 18, 22, 21, 24],
          labelData: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        },
        {
          year: 2021,
          omzet: '12.8K',
          seriesData: [19, 30, 24, 32, 24, 26, 19, 17, 24, 21, 23, 23],
          labelData: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        },
      ],
      yearOption: [
        { value: '2019', text: '2019' },
        { value: '2020', text: '2020' },
        { value: '2021', text: '2021' },
      ],
      salesLine: {
        series: [
          {
            name: 'Total Transaksi',
            data: [10, 30, 20, 30, 20, 10, 5, 10, 10, 35, 20, 25],
          },
        ],
        chartOptions: {
          chart: {
            toolbar: { show: false },
            zoom: { enabled: false },
            type: 'line',
            dropShadow: {
              enabled: true,
              top: 18,
              left: 2,
              blur: 5,
              opacity: 0.2,
            },
            offsetX: -10,
          },
          stroke: {
            curve: 'smooth',
            width: 4,
          },
          grid: {
            borderColor: '#ebe9f1',
            padding: {
              top: -20,
              bottom: 5,
              left: 20,
            },
          },
          legend: {
            show: false,
          },
          colors: ['#3b8c71'],
          fill: {
            type: 'gradient',
            gradient: {
              shade: 'dark',
              inverseColors: false,
              gradientToColors: [$themeColors.primary],
              shadeIntensity: 1,
              type: 'horizontal',
              opacityFrom: 1,
              opacityTo: 1,
              stops: [0, 100, 100, 100],
            },
          },
          markers: {
            size: 0,
            hover: {
              size: 5,
            },
          },
          xaxis: {
            labels: {
              offsetY: 5,
              style: {
                colors: '#b9b9c3',
                fontSize: '0.857rem',
              },
            },
            axisTicks: {
              show: false,
            },
            categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
            axisBorder: {
              show: false,
            },
            tickPlacement: 'on',
          },
          yaxis: {
            tickAmount: 5,
            labels: {
              style: {
                colors: '#b9b9c3',
                fontSize: '0.857rem',
              },
              formatter(val) {
                return val > 999 ? `${(val / 1000).toFixed(1)}k` : val
              },
            },
          },
          tooltip: {
            x: { show: false },
          },
        },
      },
      omzetLine: {
        series: [
          {
            name: 'Omzet',
            data: [9000000, 17000000, 14000000, 19000000, 15000000, 16000000, 9000000, 7000000, 15000000, 13000000, 14000000, 17000000],
          },
        ],
        chartOptions: {
          chart: {
            toolbar: { show: false },
            zoom: { enabled: false },
            type: 'line',
            dropShadow: {
              enabled: true,
              top: 18,
              left: 2,
              blur: 5,
              opacity: 0.2,
            },
            offsetX: -10,
          },
          stroke: {
            curve: 'smooth',
            width: 4,
          },
          grid: {
            borderColor: '#1E4393',
            padding: {
              top: -20,
              bottom: 5,
              left: 20,
            },
          },
          legend: {
            show: false,
          },
          colors: ['#1E4393'],
          fill: {
            type: 'gradient',
            gradient: {
              shade: 'dark',
              inverseColors: false,
              gradientToColors: ['#1E4393'],
              shadeIntensity: 1,
              type: 'horizontal',
              opacityFrom: 1,
              opacityTo: 1,
              stops: [0, 100, 100, 100],
            },
          },
          markers: {
            size: 0,
            hover: {
              size: 5,
            },
          },
          xaxis: {
            labels: {
              offsetY: 5,
              style: {
                colors: '#b9b9c3',
                fontSize: '0.857rem',
              },
            },
            axisTicks: {
              show: false,
            },
            categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
            axisBorder: {
              show: false,
            },
            tickPlacement: 'on',
          },
          yaxis: {
            tickAmount: 5,
            labels: {
              style: {
                colors: '#b9b9c3',
                fontSize: '0.857rem',
              },
              formatter(val) {
                // return val > 999 ? `${(val / 1000).toFixed(1)}k` : val
                return new Intl.NumberFormat('de-DE', { currency: 'EUR' }).format(val)
              },
            },
          },
          tooltip: {
            x: { show: false },
          },
        },
      },
      product: {
        perPage: 10,
        pageOptions: [3, 5, 10],
        totalRows: 1,
        currentPage: 1,
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: null,
        filterOn: [],
        infoModal: {
          id: 'info-modal',
          title: '',
          content: '',
        },
        fields: [
          { key: 'sku', label: 'SKU', sortable: true },
          { key: 'product_name', label: 'Product Name', sortable: true },
          { key: 'price', label: 'Price', sortable: true },
          { key: 'total_sales', label: 'Total Sales', sortable: true },
          { key: 'omzet', label: 'Omzet', sortable: true },
        ],
        items: [
          {
            sku: '#K0291',
            product_name: 'Kaos Arema Roar',
            price: 100000,
            total_sales: 7,
            omzet: 700000,
          },
          {
            sku: '#K0284',
            product_name: 'Kaos Ongisnade Dongker',
            price: 100000,
            total_sales: 5,
            omzet: 500000,
          },
          {
            sku: '#K0214',
            product_name: 'Kantong ASI 240ml Vita flow',
            price: 92000,
            total_sales: 17,
            omzet: 1564000,
          },
          {
            sku: '#K0255',
            product_name: 'Rendang Sapi Family Pack 100 Gram (1-2 Porsi)',
            price: 35000,
            total_sales: 34,
            omzet: 1190000,
          },
          {
            sku: '#K0249',
            product_name: 'Bakso Cup PopSo Paket isi 1 Cup - 400 Gram',
            price: 34320,
            total_sales: 29,
            omzet: 995280,
          },
          {
            sku: '#K0227',
            product_name: 'Watereri For Your Hand - 250 mL (soap)',
            price: 97500,
            total_sales: 24,
            omzet: 2340000,
          },
          {
            sku: '#K0261',
            product_name: 'Tamago Red',
            price: 30000,
            total_sales: 18,
            omzet: 540000,
          },
          {
            sku: '#K0205',
            product_name: 'Watereri Sanitary Kit for New Normal (Travel Size)',
            price: 119000,
            total_sales: 21,
            omzet: 2499000,
          },
          {
            sku: '#K0227',
            product_name: 'Clovid Antiseptic Sanitizer',
            price: 59000,
            total_sales: 19,
            omzet: 1121000,
          },
          {
            sku: '#K0293',
            product_name: '1 Dus Masker Ichinose Face Mask Earloop',
            price: 1276000,
            total_sales: 6,
            omzet: 7656000,
          },
        ],
      },
      data: {
        omzet: null,
        activeYear: 2021,
      },
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.product.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.product.items.length
    this.changeYear()
  },
  methods: {
    changeYear() {
      const year = this.data.activeYear
      const output = this.trxData.filter(item => item.year === year)

      this.data.omzet = output[0].omzet

      this.$refs.chartTransaction.updateSeries([{
        data: output[0].seriesData,
      }], false, true)

      this.$refs.chartTransaction.updateOptions({
        xaxis: {
          categories: output[0].labelData,
        },
      })
    },
    info(item, index, button) {
      this.product.infoModal.title = `Row index: ${index}`
      this.product.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.product.infoModal.id, button)
    },
    resetInfoModal() {
      this.product.infoModal.title = ''
      this.product.infoModal.content = ''
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.product.currentPage = 1
    },
  },
}
</script>
